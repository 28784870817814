import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import { Flex, Box } from "theme-ui"
import Select from "react-select"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBolt,
  faChevronRight,
  faChevronLeft
} from "@fortawesome/free-solid-svg-icons"

import iconNewMessage from "../../public/img/icon-new-message.svg"

import Modal from "../../components/Radiate/Modal/Modal"
import IntegrationModal from "../../components/IntegrationModal/IntegrationModal"
import IntegrationIconDisplay from "../IntegrationIconDisplay/IntegrationIconDisplay"
import NewBoxButton from "../Radiate/NewBoxButton/NewBoxButton"
import PopperContainer from "../Radiate/PopperContainer/PopperContainer"
import IntegrationCustomActionButton from "./IntegrationCustomActionButton"
import * as Styles from "./IntegrationActionPopperContainerStyles"

const IntegrationActionPopperContainer = ({
  disabled,
  shouldDisableThreadAction,
  actions = [],
  useInThread,
  memberId = "",
  channelId: channelIdFromThread = "",
  externalId,
  isGroup,
  onChangeNavHint,
  mobile,
  userId,
  actionContext = {},
  onUpdateText = () => {},
  onUpdateOptions = () => {},
  setFolderId,
}) => {
  const { t } = useTranslation("common")
  let actionsList = []
  const withChannels = actions.filter(o => o?.channel)
  const withoutChannels = actions.filter(o => !o?.channel)
  if (withChannels?.length) {
    let groupByIntegrationId = _.groupBy(withChannels, "integrationId")
    const processedWithChannelsIntegrations = _.map(groupByIntegrationId, (integrations, integrationId) => {
      return {
        integrationIcon: integrations[0].integrationIcon,
        integrationName: integrations[0].integrationName,
        integrationDescription: integrations[0].integrationDescription,
        integrationId,
        channels: integrations.map((o) => ({
          _id: o?.channel?._id,
          description: o?.channel?.description,
          name: o?.channel?.name,
          iframeContext: o.iframeContext,
          contextToken: o.contextToken,
          integrationBuild: o.integrationBuild,
          customActions: o.customActions,
          environments: o?.channel?.environments,
        }))
      }
    })
    actionsList = [...actionsList, ...processedWithChannelsIntegrations]
  }
  actionsList = [...actionsList, ...withoutChannels]
  const [selectedIntegrationId, setSelectedIntegrationId] = useState("")
  const [showIntegrationDropDown, setShowIntegrationDropDown] = useState(false)
  const [openIntegrationModalUrl, setOpenIntegrationModalUrl] = useState(null)
  const selectedIntegration = (actionsList || []).find(o => o.integrationId === selectedIntegrationId)
  const [selectedChannelId, setSelectedChannelId] = useState("")
  const [modalWidth, setModalWidth] = useState(null)
  const [modalHeight, setModalHeight] = useState(null)
  let channelOptions = (selectedIntegration?.channels || []).map((channel) => {
    return ({
      label: channel.name,
      value: channel._id,
      ...channel
    })
  })
  if (channelOptions.some(o => o?.environments?.length)) {
    channelOptions = channelOptions.filter((o) => {
      let installedInboxEnvNumber = 0 
      o.environments.forEach((env) => {
        if (env.webhooks?.normalizedInbound?.length) {
          if (env.webhooks.normalizedInbound.find(o => o?.integrationId === "inbox")) {
            installedInboxEnvNumber += 1
          }
        }
        if (env.webhooksV2?.length) {
          if (env.webhooksV2.find(o => o.integrationId === "inbox")) {
            installedInboxEnvNumber += 1
          }
        }
      })
      return installedInboxEnvNumber > 0
    })
  }
  let selectedIntegrationActionsList = []
  if (selectedIntegration?.channels?.length && channelOptions?.length && selectedChannelId) {
    selectedIntegrationActionsList = (selectedIntegration?.channels || []).find(o => o?._id === selectedChannelId)?.customActions
  } else if (selectedIntegration) {
    selectedIntegrationActionsList = selectedIntegration?.customActions
  }
  const selectedChannel = channelOptions.find(o => o._id === selectedChannelId) || null
  const selectediframeContext = withChannels?.length && channelOptions?.length && selectedChannelId ? selectedChannel?.iframeContext : selectedIntegration?.iframeContext
  const selectedContextToken = withChannels?.length && channelOptions?.length && selectedChannelId ? selectedChannel?.contextToken : selectedIntegration?.contextToken

  let buttonDisplay
  if (useInThread) {
    buttonDisplay = (
      <Styles.IconButton
        mobile={mobile}
        className="folder-action-button"
        borderless
        text={(
          <div
            className="svg-container"
          >
            <img
              src={iconNewMessage}
              alt="New Message"
              className="svg-icon"
            />
          </div>
        )}
        inverted={!!mobile}
        size={mobile ? "L" : null}
        $on={showIntegrationDropDown}
        onClick={() => {
          if (disabled) {
            return
          }
          if (showIntegrationDropDown) {
            setSelectedIntegrationId("")
          }
          setShowIntegrationDropDown(!showIntegrationDropDown)
        }}
      />
    )
  } else {
    buttonDisplay = (
      <Styles.IntegrationButton
        className="icon"
        icon={faBolt}
        fixedWidth
        disabled={disabled}
        onClick={() => {
          if (disabled) {
            return
          }
          if (showIntegrationDropDown) {
            setSelectedIntegrationId("")
          }
          setShowIntegrationDropDown(!showIntegrationDropDown)
        }}
      >
        <FontAwesomeIcon
          className="icon"
          icon={faBolt}
          fixedWidth
        />
      </Styles.IntegrationButton>
    )
  }
  return (
    <>
      <PopperContainer
        show={showIntegrationDropDown}
        disabled={disabled}
        display={buttonDisplay}
        placement={useInThread ? "bottom-start" : "top-start"}
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 8],
            },
          },
        ]}
        onClickOutside={() => {
          if (showIntegrationDropDown) {
            setShowIntegrationDropDown(false)
            setSelectedIntegrationId("")
            setSelectedChannelId("")
          }
        }}
      >
        {({ update }) => (
          <Styles.IntegrationsContainer>
            {selectedIntegrationId ?
              <Styles.IntegrationCustomActionsContainer>
                <Flex
                  pr={2}
                  sx={{
                    alignItems: "center",
                    height: "74px",
                    borderBottom: "1px solid",
                    borderBottomColor: "gray1",
                  }}
                  title={selectedIntegration?.integrationDescription}
                >
                  <NewBoxButton
                    icon={faChevronLeft}
                    borderless
                    onClick={() => {
                      setSelectedIntegrationId("")
                      setSelectedChannelId("")
                      update()
                    }}
                  />
                  <IntegrationIconDisplay
                    icon={selectedIntegration?.integrationIcon}
                    name={selectedIntegration?.integrationName}
                    size="40px"
                  />
                  <Box pl={2} >
                    <div className="integration-name">{selectedIntegration?.integrationName}</div>
                    <div className="integration-description">{selectedIntegration?.integrationDescription}</div>
                  </Box>
                </Flex>
                {selectedIntegration?.channels?.length &&
                  <Styles.ChannelSelectWrapper>
                    <Select
                      isSearchable={false}
                      placeholder={t?.("threads.select_channel")}
                      options={channelOptions}
                      value={selectedChannel}
                      onChange={(value) => {
                        setSelectedChannelId(value.value)
                      }}
                      style={{
                        menu: styles => ({
                          ...styles,
                          zIndex: "9999",
                          position: "absolute"
                        }),
                        option: styles => ({
                          ...styles,
                          zIndex: "9999"
                        })
                      }}
                    />
                  </Styles.ChannelSelectWrapper>
                }
                <Styles.CustomActionsList withPicker={!!selectedIntegration?.channels?.length}>
                  {(selectedIntegrationActionsList || []).map((n, i) => (
                    <IntegrationCustomActionButton
                      key={i}
                      shouldDisableThreadAction={shouldDisableThreadAction}
                      customAction={n}
                      setModalWidth={setModalWidth}
                      setModalHeight={setModalHeight}
                      memberId={memberId}
                      channelId={channelIdFromThread || selectedChannelId}
                      userId={userId}
                      actionContext={actionContext}
                      contextToken={selectedIntegration?.contextToken}
                      onOpenIntegrationModal={setOpenIntegrationModalUrl}
                      onUpdateOptions={onUpdateOptions}
                      onUpdateText={onUpdateText}
                      onClick={() => {
                        setShowIntegrationDropDown(false)
                      }}
                      useInThread={useInThread}
                    />
                  ))}
                </Styles.CustomActionsList>
              </Styles.IntegrationCustomActionsContainer>
              :
              <Styles.IntegrationInputActionsContainer>
                {(actionsList || []).map((integration, i) => {
                  return (
                    <Styles.IntegrationItem
                      key={i}
                      onClick={() => {
                        setSelectedIntegrationId(integration?.integrationId)
                        update()
                      }}
                    >
                      <Flex sx={{ alignItems: "center" }}>
                        <IntegrationIconDisplay
                          icon={integration?.integrationIcon}
                          name={integration?.integrationName}
                        />
                        <Box pl={2} pr={2}>
                          <div className="integration-name">{integration?.integrationName}</div>
                          <div className="integration-description">{integration?.integrationDescription}</div>
                        </Box>
                      </Flex>
                      <NewBoxButton
                        icon={faChevronRight}
                        borderless
                      />
                    </Styles.IntegrationItem>
                  )
                })}
              </Styles.IntegrationInputActionsContainer>
            }
          </Styles.IntegrationsContainer>
        )}
      </PopperContainer>
      <Modal
        open={!!openIntegrationModalUrl}
      >
        {() => {
          const processedMeta = {}
          if (userId) processedMeta.userId = userId
          if (memberId) processedMeta.memberId = memberId
          return (
            <IntegrationModal
              setFolderId={setFolderId}
              url={openIntegrationModalUrl}
              iframeContext={selectediframeContext}
              contextToken={selectedContextToken}
              meta={processedMeta}
              onClose={() => {
                setOpenIntegrationModalUrl(null)
              }}
              onChangeNavHint={onChangeNavHint}
              modalWidth={modalWidth}
              modalHeight={modalHeight}
              externalId={externalId}
              isGroup={isGroup}
            />
          )
        }}
      </Modal>
    </>
  )
}

export default IntegrationActionPopperContainer
