import React from "react"
import PropTypes from "prop-types"
import _ from "lodash"

import { hexToRgb } from "../../util"
import "./Toggle.scss"

const propTypes = {
  disabled: PropTypes.bool,
  defaultValue: PropTypes.bool,
  value: PropTypes.bool,
  onColor: PropTypes.string,
  offColor: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
}

const defaultProps = {
  disabled: false,
  defaultValue: false,
  value: undefined,
  onColor: "#39b34a",
  offColor: "#e3e5e8",
  className: "",
  onChange: () => {},
  onFocus: () => {}
}

const Toggle = class Toggle extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
    }
  }

  render() {
    const {
      disabled,
      value,
      onColor,
      offColor,
      className,
      onFocus,
      onChange,
      notAuth,
      notAuthPlacement,
      ...props
    } = this.props

    let defaultClassName = "radiate-theme toggle-container"
    const on = value

    const rootProperty = {
      disabled,
      on,
      className,
    }

    defaultClassName = _.reduce(rootProperty, (classNames, val, key) => {
      if (val) {
        if (key === "className") {
          return `${classNames} ${val}`
        }
        return `${classNames} ${key}`
      }
      return classNames
    }, defaultClassName)

    const style = {
      backgroundColor: offColor
    }

    if (disabled) {
      if (on) {
        const rgb = hexToRgb(onColor)
        style.backgroundColor = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.3)`
      } else {
        const rgb = hexToRgb(offColor)
        style.backgroundColor = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.3)`
      }
    } else if (on) {
      style.backgroundColor = onColor
    }

    return (
      <div
        style={style}
        className={defaultClassName}
        onClick={() => {
          if (!disabled) {
            onChange(!value)
            onFocus()
          }
        }}
        {...props}
      >
        <span className="handle"></span>
      </div>
    )
  }
}

Toggle.defaultProps = defaultProps
Toggle.propTypes = propTypes

export default Toggle
