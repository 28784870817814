import styled from "@emotion/styled"

export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  
  padding: 16px 0;
  cursor: pointer;
`

export const SectionTitleText = styled.div`
  font-size: 16px;
  color: #2D3748;
`

export const SectionContent = styled.div`
  overflow: hidden;
  transition: max-height 0.4s ease-in-out, opacity 0.3s ease-in-out;
  max-height: ${props => props.isOpen ? "2000px" : "0"};
  opacity: ${props => props.isOpen ? 1 : 0};
  
  /* Add a slight delay to opacity when opening */
  ${props => props.isOpen && `
    transition: max-height 0.4s ease-in-out, opacity 0.3s ease-in-out 0.1s;
  `}
`

export const TableHeader = styled.thead`
  color: #718096;
  text-transform: uppercase;
  th {
    font-weight: 400;
    padding-bottom: 16px;
    font-size: 14px;
    text-align: left;
    border-bottom: 1px solid #EDF2F7;

  }
`

export const FlexRow = styled.tr`
  position: relative;
  background: #ffffff ;
  font-size: ${(props) => props.theme.textSm};
  color: ${(props) => props.theme.gray9};

  >td {
    padding: 8px;
  }

  >.email {

    >.name {
      color: ${(props) => props.theme.gray6};
    }
  }

  .wchannel-id {
    font-size: 0.7rem;
    color: ${(props) => props.theme.gray6};
  }
`

export const Container = styled.div`
  padding: 0px 32px;
  overflow-y: auto;
  height: 100%;
  position: relative;
  padding-bottom: 32px;

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: white;
  position: sticky;
  top: 0;
  z-index: 1;
  border-bottom: 1px solid ${props => props.theme.colors.gray1};
  padding: 16px 0;
`

export const StyledTable = styled.table`
  width: 100%;
  table-layout: fixed;

  th:first-child,
  td:first-child {
    padding-right: 32px;
  }
`

export const FeatureTitle = styled.div`
  font-size: 14px;
  color: ${props => props.theme.colors.gray9};
`

export const FeatureDescription = styled.div`
  font-size: 12px;
  color: ${props => props.theme.colors.gray6};
  margin-top: 4px;
`

export const ExtensionIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 12px;
`

export const ExtensionNameWrapper = styled.div`
  display: flex;
  align-items: center;
`