import React, { useEffect, useState, memo } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Flex, Box } from "theme-ui"
import _ from "lodash"
import {
  faTimes,
} from "@fortawesome/free-solid-svg-icons"
import { LoadingMask } from "../../CommonStyles"
import * as Styles from "./IntegrationModalStyles"
import theme from "../../stylesheets/theme"
import { toast } from "react-hot-toast"
import { GROUP_PREFIX } from "../../const"

const IntegrationModal = ({
  url,
  iframeContext,
  contextToken,
  integrationName = "",
  onClose,
  onChangeNavHint,
  modalWidth,
  modalHeight,
  externalId,
  setFolderId = () => {},
  isGroup = false,
}) => {

  const [integrationHeader, setIntegrationHeader] = useState(null)
  const [iframeFinishLoading, setiframeFinishLoading] = useState(false)

  const postMessageToIframe = () => {
    let viewOrigin
    if (url) {
      viewOrigin = new URL(url).origin
    }
    const iframe = document.getElementById("myframe")
    iframe.contentWindow.postMessage({
      ...iframeContext,
      ctx: contextToken,
    }, viewOrigin || "*")
  }

  const receiveMessage = (event) => {
    if (url) {
      const viewOrigin = new URL(url).origin
      if (event.origin === viewOrigin) {
        if (event.data?.action === "iframeToastNotification") {
          const toastType = event.data?.meta?.toastType
          const toastMessage = event.data?.meta?.toastMessage
          const toastOptions = event.data?.meta?.toastOptions

          if (!toastType || toastType === "success") {
            toast.success(toastMessage, toastOptions)
          } else if (toastType === "error") {
            toast.error(toastMessage, toastOptions)
          }
        }
        if (event.data === "iframeFinishLoading") {
          setiframeFinishLoading(true)
          postMessageToIframe()
        }
        if (event.data === "iframeCloseModal") {
          onClose()
        }
        if (event.data?.action === "iframeCloseModal") {
          if (event.data?.meta?.navHint) {
            onChangeNavHint(event.data?.meta?.navHint)
          }
          onClose()
        }

        if (event.data?.action === "iframeHeaderUpdate") {
          if (event.data?.meta?.title) {
            setIntegrationHeader(event.data?.meta?.title)
          }
        }
        if (event.data?.action === "iframeChangeFolder") {
          const folderId = event.data?.meta?.folderId
          if (folderId && setFolderId) {
            setFolderId(folderId)
          }
        }
      }
    }
  }

  useEffect(() => {
    setiframeFinishLoading(false)
    window.addEventListener("message", receiveMessage)
    return () => {
      window.removeEventListener("message", receiveMessage)
    }
  }, [url])

  let modURL
  if (url) {
    const urlObj = new URL(url)
    urlObj.searchParams.append("ctx", contextToken)

    if (isGroup) {
      const groupId = externalId.replace(GROUP_PREFIX, "")
      urlObj.searchParams.append("groupId", groupId)
    }
    modURL = urlObj.href
  }

  return (
    <Styles.IntegrationModalContainer height={modalHeight} width={modalWidth}>
      <Flex px={4} py={4} sx={{
        alignItems: "center",
        justifyContent: "space-between",
        background: theme.themeColorDarkV2,
        color: theme.white,
      }}>
        <Box
          sx={{
            color: theme.white,
            fontWeight: "700",
            fontSize: "20px",
          }}
        >
          {integrationHeader}
        </Box>
        <FontAwesomeIcon
          className="close-button"
          icon={faTimes}
          onClick={() => {
            onClose()
          }}
          style={{
            color: theme.white,
          }}
        />
      </Flex>
      <div className="separator"></div>
      <Box sx={{ position: "relative", height: "calc(100% - 81px)", width: "100%" }}>
        {!iframeFinishLoading &&
          <div style={{ position: "relative", height: "100%", width: "100%", background: "#FFFFFF" , zIndex: 9}}>
            <LoadingMask style={{ height: "100%", width: "100%", background: "#FFFFFF" }}>
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
            </LoadingMask>
          </div>
        }
        <iframe
          allow="fullscreen; microphone; camera; display-capture; geolocation; clipboard-write;"
          className="iframe"
          id="myframe"
          src={modURL || ""}
          title={integrationName}
          style={{ width: "100%", height: "100%", border: "none" }}
        ></iframe>
      </Box>
    </Styles.IntegrationModalContainer>
  )
}

export default memo(IntegrationModal, (prevProps, currentProps) => {
  return _.isEqual(_.omit(prevProps, ["contextToken", "onClose", "onChangeNavHint"]), _.omit(currentProps, ["contextToken", "onClose", "onChangeNavHint"]))
})
