/* eslint-disable no-unused-vars */
/** @jsxImportSource theme-ui */
import { useState } from "react"
import _ from "lodash"
import {
  useMutation,
  useFragment,
} from "react-relay"
import graphql from "babel-plugin-relay/macro"
import {
  UpdateAppMutation
} from "./SettingsModalQuery"
import { Checkbox, Flex } from "theme-ui"
import BoxButton from "../Radiate/NewBoxButton/NewBoxButton"
import * as Styles from "./SettingsOrganizationConfigStyles"
import ToggleField from "../Radiate/ToggleField/ToggleField"

const Section = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Styles.SectionHeader onClick={() => setIsOpen(!isOpen)}>
        <Styles.SectionTitleText>{title}</Styles.SectionTitleText>
        <BoxButton
          icon={isOpen ? "chevron-down" : "chevron-right"}
          borderless
        />
      </Styles.SectionHeader>
      <Styles.SectionContent isOpen={isOpen}>
        {children}
      </Styles.SectionContent>
    </>
  )
}

const CheckboxField = ({
  label,
  disabled,
  checked,
  onClick,
  className,
}) => (
  <Flex
    className={className}
    sx={{
      alignItems: "center",
      color: disabled ? "gray4" : "gray9",
      cursor: disabled ? "" : "pointer"
    }}
    onClick={disabled ? undefined : onClick}
  >
    <Checkbox
      checked={checked}
      disabled={disabled}
      sx={{
        color: disabled ? "gray3" : "gray6",
      }}
    />
    <span>{label}</span>
  </Flex>
)

const automationAppConfigFields = [
  {
    label: "Internal Comment Notification",
    key: "INBOX_AGENT_INTERNAL_COMMENT",
    description: "Send webhook notifications when internal comments are added from agents.",
    default: false,
  },
  {
    label: "Agent Message Notification",
    key: "INBOX_AGENT_MESSAGE",
    description: "Send webhook notifications when agent messages are added from agents.",
    default: false,
  },
  {
    label: "Agent Assignment Notification",
    key: "INBOX_ASSIGN_AGENT",
    description: "Send webhook notifications when agent assignments are made.",
    default: false,
  },
  {
    label: "Livechat Update Notification",
    key: "INBOX_LIVECHAT_UPDATE",
    description: "Send webhook notifications when livechat updates are made.",
    default: false,
  },
  {
    label: "Thread Folder Movement Notification",
    key: "MOVE_THREAD_FOLDER",
    description: "Send webhook notifications when thread folders are moved.",
    default: true,
  },
]

const roleBasedAppConfigFields = [
  {
    label: "Send Message",
    key: "SEND_MESSAGE_ALL",
    description: "Allows selected roles to send messages to customers.",
    type: "feature",
    default: ["AGENT", "MANAGER"],
  },
  {
    label: "Send Attachment",
    key: "SEND_ATTACHMENT",
    description: "Allows selected roles to share files and images in conversations.",
    type: "feature",
    default: ["AGENT", "MANAGER"],
  },
  {
    label: "Send Audio",
    key: "SEND_AUDIO",
    description: "Allows selected roles to send voice messages in conversations.",
    type: "feature",
    default: ["AGENT", "MANAGER"],
  },
  {
    label: "Transfer to Private Folders",
    key: "TRANSFER_TO_UNASSOCIATED_FOLDERS",
    description: "Allows selected roles to move conversations to folders they don't have direct access to.",
    type: "feature",
    default: ["MANAGER"],
  },
  // TODO: Enable this feature after we have filtering feature for extensions
  // {
  //   label: "Use Extensions",
  //   key: "USE_EXTENSION",
  //   description: "Configure which roles can access specific extensions.",
  //   type: "feature",
  //   default: [{
  //     extensionId: "*",
  //     roles: ["AGENT", "MANAGER"],
  //   }],
  // },
  {
    label: "Mask External ID",
    key: "MASK_EXTERNALID",
    description: "Mask the external ID within inbox.",
    type: "privacy",
    default: [],
  },
]

const SettingsOrganizationConfig = ({
  currentWAppId,
  navBack,
  ...props
}) => {
  const data = useFragment(
    graphql`
      fragment SettingsOrganizationConfig_userViewer on UserScope {
        folderActions {
          integrationIcon
          integrationName
          integrationId
        }
        app {
          roleBasedAppConfig {
            MASK_EXTERNALID
          }
          defaultChannelConfig {
            automation {
              INBOX_AGENT_INTERNAL_COMMENT
              INBOX_AGENT_MESSAGE
              INBOX_ASSIGN_AGENT
              INBOX_LIVECHAT_UPDATE
              MOVE_THREAD_FOLDER
            }
            roleBasedConfig {
              SEND_MESSAGE_ALL
              SEND_ATTACHMENT
              SEND_AUDIO
              TRANSFER_TO_UNASSOCIATED_FOLDERS
              # TODO: Enable this feature after we have filtering feature for extensions
              # USE_EXTENSION {
              #   extensionId
              #   roles
              # }
            }
          }
        }
      }
    `,
    props.data
  )
  const app = data?.app
  const uniqExtensions = _.uniqBy(data?.folderActions, "integrationId")
  const [error, setError] = useState("")
  const [updateApp, loading] = useMutation(
    UpdateAppMutation
  )
  
  
  const [automationAppConfig, setAutomationAppConfig] = useState(
    automationAppConfigFields.reduce((acc, field) => ({
      ...acc,
      [field.key]: app?.defaultChannelConfig?.automation?.[field.key] ?? field?.default ?? false
    }), {})
  )

  const [roleBasedAppConfig, setRoleBasedAppConfig] = useState(
    roleBasedAppConfigFields.reduce((acc, field) => {
      if (field.key === "MASK_EXTERNALID") {
        return {
          ...acc,
          [field.key]: app?.roleBasedAppConfig?.[field.key] || field?.default || []
        };
      }
      
      return {
        ...acc,
        [field.key]: field.key === "USE_EXTENSION" 
          ? (app?.defaultChannelConfig?.roleBasedConfig?.USE_EXTENSION || field?.default || [])
          : (app?.defaultChannelConfig?.roleBasedConfig?.[field.key] || field?.default || [])
      };
    }, {})
  )
  
  const [extensionPermissions, setExtensionPermissions] = useState(
    uniqExtensions?.reduce((acc, extension) => {
      const existingConfig = (app?.defaultChannelConfig?.roleBasedConfig?.USE_EXTENSION || [])
        .find(e => e.extensionId === extension.integrationId);
      
      const defaultConfig = (app?.defaultChannelConfig?.roleBasedConfig?.USE_EXTENSION || [])
        .find(e => e.extensionId === "*");
      
      const defaultField = roleBasedAppConfigFields.find(field => field.key === "USE_EXTENSION");
      const defaultRoles = defaultField?.default?.[0]?.roles || ["AGENT", "MANAGER"];
      
      return {
        ...acc,
        [extension.integrationId]: {
          agent: existingConfig 
            ? existingConfig.roles.includes("AGENT")
            : defaultConfig
              ? defaultConfig.roles.includes("AGENT")
              : defaultRoles.includes("AGENT"),
          manager: existingConfig
            ? existingConfig.roles.includes("MANAGER")
            : defaultConfig
              ? defaultConfig.roles.includes("MANAGER")
              : defaultRoles.includes("MANAGER")
        }
      };
    }, {})
  )

  const handleRoleToggle = (key, role) => {
    setRoleBasedAppConfig(prev => {
      const roles = prev[key] || []
      const newRoles = roles.includes(role) 
        ? roles.filter(r => r !== role)
        : [...roles, role]

      // If turning off SEND_MESSAGE_ALL, also turn off dependent permissions
      if (key === "SEND_MESSAGE_ALL" && roles.includes(role)) {
        return {
          ...prev,
          [key]: newRoles,
          SEND_ATTACHMENT: prev.SEND_ATTACHMENT.filter(r => r !== role),
          SEND_AUDIO: prev.SEND_AUDIO.filter(r => r !== role)
        }
      }

      return {
        ...prev,
        [key]: newRoles
      }
    })
  }

  const updateAppFunction = ({
    roleBasedAppConfig,
    automationAppConfig,
  }) => {
    let clientMutationId = 0
    const mutationParams = {
      variables: {
        input: {
          defaultChannelConfig: {
            roleBasedConfig: {
              ..._.omit(roleBasedAppConfig, ["MASK_EXTERNALID"]),
              USE_EXTENSION: Object.entries(extensionPermissions)
                .map(([extensionId, roles]) => ({
                  extensionId,
                  roles: [
                    ...(roles.agent ? ["AGENT"] : []),
                    ...(roles.manager ? ["MANAGER"] : [])
                  ].sort()
                }))
                .filter(ext => ext.roles.length > 0)
            },
            automation: automationAppConfig,
          },
          roleBasedAppConfig: {
            MASK_EXTERNALID: roleBasedAppConfig.MASK_EXTERNALID
          },
          clientMutationId: clientMutationId++,
        },
      },
      onCompleted: (response, errs) => {
        if (!response.updateApp?.error) {
        } else {
          setError(response.updateApp?.error?.message)
        }
      },
      onError: (err) => {
        const errorMsg = err.res?.json?.errors[0]?.message || err
        setError(errorMsg)
      }
    }
    updateApp(mutationParams)
  }

  const handleExtensionToggle = (extensionId, role) => {
    setExtensionPermissions(prev => ({
      ...prev,
      [extensionId]: {
        ...prev[extensionId],
        [role.toLowerCase()]: !prev[extensionId][role.toLowerCase()]
      }
    }))
  }

  return (
    <Styles.Container>
      <Styles.Header>
        <BoxButton
          text="Update"
          primary
          loading={loading}
          disabled={
            _.isEqual(
              { MASK_EXTERNALID: app?.roleBasedAppConfig?.MASK_EXTERNALID || [] }, 
              { MASK_EXTERNALID: roleBasedAppConfig.MASK_EXTERNALID }
            ) && 
            _.isEqual(
              _.omit(app?.defaultChannelConfig?.roleBasedConfig || {}, "USE_EXTENSION"),
              _.omit(roleBasedAppConfig, ["USE_EXTENSION", "MASK_EXTERNALID"])
            ) &&
            // _.isEqual(
            //   app?.defaultChannelConfig?.roleBasedConfig?.USE_EXTENSION || [],
            //   Object.entries(extensionPermissions)
            //     .map(([extensionId, roles]) => ({
            //       extensionId,
            //       roles: [
            //         ...(roles.agent ? ["AGENT"] : []),
            //         ...(roles.manager ? ["MANAGER"] : [])
            //       ].sort()
            //     }))
            //     .filter(ext => ext.roles.length > 0)
            // ) &&
            _.isEqual(
              automationAppConfigFields.reduce((acc, field) => ({
                ...acc,
                [field.key]: app?.defaultChannelConfig?.automation?.[field.key] ?? false
              }), {}),
              automationAppConfig
            )
          }
          onClick={() => {
            updateAppFunction({
              roleBasedAppConfig: roleBasedAppConfig,
              automationAppConfig: automationAppConfig,
            })
          }}
        />
      </Styles.Header>

      <Section title="Role-based Feature Control">
        <Styles.StyledTable>
          <Styles.TableHeader>
            <tr>
              <th>Feature</th>
              <th>Roles</th>
            </tr>
          </Styles.TableHeader>
          <tbody>
            {roleBasedAppConfigFields
              .filter(field => field.type === "feature")
              .filter(field => {
                // Only show USE_EXTENSION if there are extensions
                if (field.key === "USE_EXTENSION") {
                  return uniqExtensions?.length > 0
                }
                return true
              })
              .map(field => (
                field.key === "USE_EXTENSION" ? (
                  <Styles.FlexRow key={field.key}>
                    <td colSpan="2">
                      <Styles.FeatureTitle>{field.label}</Styles.FeatureTitle>
                      <Styles.FeatureDescription>
                        {field.description}
                      </Styles.FeatureDescription>
                      
                      <Styles.StyledTable sx={{ mt: 3 }}>
                        <Styles.TableHeader>
                          <tr>
                            <th>Extension</th>
                            <th>Roles</th>
                          </tr>
                        </Styles.TableHeader>
                        <tbody>
                          {uniqExtensions?.map((extension) => (
                            <Styles.FlexRow key={extension.integrationId}>
                              <td>
                                <Styles.ExtensionNameWrapper>
                                  {extension.integrationIcon && (
                                    <Styles.ExtensionIcon 
                                      src={extension.integrationIcon} 
                                      alt={extension.integrationName}
                                    />
                                  )}
                                  <Styles.FeatureTitle>{extension.integrationName}</Styles.FeatureTitle>
                                </Styles.ExtensionNameWrapper>
                              </td>
                              <td>
                                <Flex>
                                  <CheckboxField
                                    label="Manager"
                                    onClick={() => handleExtensionToggle(extension.integrationId, "MANAGER")}
                                    checked={extensionPermissions[extension.integrationId]?.manager}
                                    sx={{ mr: 2 }}
                                  />
                                  <CheckboxField
                                    label="Agent"
                                    onClick={() => handleExtensionToggle(extension.integrationId, "AGENT")}
                                    checked={extensionPermissions[extension.integrationId]?.agent}
                                  />
                                </Flex>
                              </td>
                            </Styles.FlexRow>
                          ))}
                        </tbody>
                      </Styles.StyledTable>
                    </td>
                  </Styles.FlexRow>
                ) : (
                  <Styles.FlexRow key={field.key}>
                    <td>
                      <Styles.FeatureTitle>{field.label}</Styles.FeatureTitle>
                      <Styles.FeatureDescription>
                        {field.description}
                        {(field.key === "SEND_ATTACHMENT" || field.key === "SEND_AUDIO") && 
                          " Requires Send Message enabled."}
                      </Styles.FeatureDescription>
                    </td>
                    <td>
                      <Flex>
                        <CheckboxField
                          label="Manager"
                          onClick={() => handleRoleToggle(field.key, "MANAGER")}
                          disabled={
                            (field.key === "SEND_ATTACHMENT" || field.key === "SEND_AUDIO") && 
                            !roleBasedAppConfig.SEND_MESSAGE_ALL.includes("MANAGER")
                          }
                          checked={roleBasedAppConfig[field.key].includes("MANAGER")}
                          sx={{ mr: 2 }}
                        />
                        <CheckboxField
                          label="Agent"
                          onClick={() => handleRoleToggle(field.key, "AGENT")}
                          disabled={
                            (field.key === "SEND_ATTACHMENT" || field.key === "SEND_AUDIO") && 
                            !roleBasedAppConfig.SEND_MESSAGE_ALL.includes("AGENT")
                          }
                          checked={roleBasedAppConfig[field.key].includes("AGENT")}
                        />
                      </Flex>
                    </td>
                  </Styles.FlexRow>
                )
              ))}
          </tbody>
        </Styles.StyledTable>
      </Section>
      <Section title="Role-based Privacy Settings">
        <Styles.StyledTable>
          <Styles.TableHeader>
            <tr>
              <th>Feature</th>
              <th>Roles</th>
            </tr>
          </Styles.TableHeader>
          <tbody>
            {roleBasedAppConfigFields
              .filter(field => field.type === "privacy")
              .map(field => (
                <Styles.FlexRow key={field.key}>
                  <td>
                    <Styles.FeatureTitle>{field.label}</Styles.FeatureTitle>
                    <Styles.FeatureDescription>
                      {field.description}
                    </Styles.FeatureDescription>
                  </td>
                  <td>
                    <Flex>
                      <CheckboxField
                        label="Admin"
                        onClick={() => handleRoleToggle(field.key, "ADMIN")}
                        checked={roleBasedAppConfig[field.key].includes("ADMIN")}
                        sx={{ mr: 2 }}
                      />
                      <CheckboxField
                        label="User"
                        onClick={() => handleRoleToggle(field.key, "USER")}
                        checked={roleBasedAppConfig[field.key].includes("USER")}
                      />
                    </Flex>
                  </td>
                </Styles.FlexRow>
              ))}
          </tbody>
        </Styles.StyledTable>
      </Section>

      <Section title="Webhook Event Notifications">
        <Styles.StyledTable>
          <Styles.TableHeader>
            <tr>
              <th>Event Type</th>
              <th>Send to Webhook</th>
            </tr>
          </Styles.TableHeader>
          <tbody>
            {automationAppConfigFields?.map((field) => (
              <Styles.FlexRow key={field.key}>
                <td>
                  <Styles.FeatureTitle>{field.label}</Styles.FeatureTitle>
                  <Styles.FeatureDescription>
                    {field.description}
                  </Styles.FeatureDescription>
                </td>
                <td>
                  <ToggleField
                    value={automationAppConfig?.[field.key] ?? false}
                    onChange={() => setAutomationAppConfig(prev => ({
                      ...prev,
                      [field.key]: !(prev?.[field.key] ?? false)
                    }))}
                  />
                </td>
              </Styles.FlexRow>
            ))}
          </tbody>
        </Styles.StyledTable>
      </Section>
    </Styles.Container>
  )
}

export default SettingsOrganizationConfig
