/** @jsxImportSource theme-ui */
import React, { useEffect, useState, useTransition, useMemo, useCallback, useRef, PureComponent, useContext } from "react"
import { useTranslation, withTranslation } from "react-i18next"
import { useHistory } from "yarr"
import { Flex, Text } from "theme-ui"
import AutoSizer from "react-virtualized-auto-sizer"
import { FixedSizeList as List } from "react-window"
import InfiniteLoader from "react-window-infinite-loader"
import _ from "lodash"
import qs from "query-string"
import moment from "moment"
import graphql from "babel-plugin-relay/macro"
import SwipeToDelete from "react-swipe-to-delete-ios"
import {
  usePaginationFragment,
  useSubscription,
  useMutation
} from "react-relay"
import { ConnectionHandler } from "relay-runtime"
import ContentLoader from "react-content-loader"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faInbox, faRotateRight } from "@fortawesome/free-solid-svg-icons"
import { faSquare, faSquareCheck } from "@fortawesome/free-regular-svg-icons"

import * as Styles from "./InboxStyles"
import IconContainer from "../../components/IconContainer/IconContainer"
import IntegrationIconDisplay from "../../components/IntegrationIconDisplay/IntegrationIconDisplay"
import NewBoxButton from "../../components/Radiate/NewBoxButton/NewBoxButton"

import { ThreadsUpdateThreadFolderMutation } from "./InboxQuery"
import ManageThreadsPopper from "../../components/ManageThreadsPopper/ManageThreadsPopper"
import { FOLLOW_UP_TEMP_FOLDER_ID } from "../../const"
import ReactTooltip from "../../components/ReactTooltip/ReactTooltip"
import { renderUserNameInboxProfileFirst } from "../../components/util"
import { UserContext } from "../../contexts/UserContext"
const GUTTER_SIZE = 8

const Loader = ({
  mobile = false,
  ...props
}) => (
  <ContentLoader
    speed={2}
    height={mobile ? 96 : 88}
    viewBox="0 0 100% 88"
    backgroundColor="#e6e6e6"
    foregroundColor="#f0f0f0"
    {...props}
  >
    <circle cx={mobile ? "49" : "36"} cy={mobile ? "46" : "42"} r={mobile ? "25" : "20"} />
    <rect x={mobile ? "90" : "62"} y={mobile ? "26" : "22"} rx="3" ry="3" width="180" height="6" />
    <rect x={mobile ? "90" : "62"} y={mobile ? "43" : "39"} rx="3" ry="3" width="100" height="6" />
    <rect x={mobile ? "90" : "62"} y={mobile ? "60" : "56"} rx="3" ry="3" width="150" height="6" />
  </ContentLoader>
)

const ThreadSubscription = graphql`
  subscription ThreadsQuerySubscription (
    $folderId: ID
    $wChannelIds: [ID]
    $assigned: Boolean
    $onlyUnread: Boolean
  ) {
    thread (
      folderId: $folderId,
      wChannelIds: $wChannelIds,
      assigned: $assigned,
      onlyUnread: $onlyUnread
    ) {
      action
      thread {
        threadId
        folderId
        folder {
          folderId
          name
          color
        }
        wMemberId
        wChannelId
        wChannel {
          name
          integrationIcon
          integrationName
        }
        etag
        title
        updatedAt
        lastChatAt
        lastMessage
        lastReadAt
        unreadCount
        member {
          groupChat
          externalId
          meta
          externalProfile
          inboxProfile {
            firstName
            lastName
            name
            profilePicUrl
          }
        }
      }
    }
  }
`

const innerElementType = React.forwardRef(({ style, ...rest }, ref) => (
  <div
    ref={ref}
    style={{
      ...style,
      height: `${parseFloat(style.height) + (GUTTER_SIZE * 2)}px`,
      // paddingLeft: GUTTER_SIZE,
      // paddingTop: GUTTER_SIZE,
    }}
    {...rest}
  />
))

const minimumBatchSize = 30

class Row extends PureComponent {
  renderThread({ threadObj, t }) {
    const {
      mobile,
      updateThreadFolder,
      threadId,
      selectedAllThreads,
      selecting,
      onChangeThreadId,
      selectedThreads,
      setSelectedThreads,
      isInFlightThreadFolder,
    } = this.props.data

    const isGroup = threadObj?.member?.groupChat
    const isThreadDeleted = threadObj?.member?.meta?.isGroupDeleted

    function getLastChatTimeText(momentDate) {
      const REFERENCE = moment()
      const TODAY = REFERENCE.clone().startOf('day');
      const YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');
      const A_WEEK_OLD = REFERENCE.clone().subtract(6, 'days').startOf('day');

      function isToday(momentDate) {
        return momentDate.isSame(TODAY, 'd');
      }

      function isYesterday(momentDate) {
        return momentDate.isSame(YESTERDAY, 'd');
      }
      function isWithinAWeek(momentDate) {
        return momentDate.isAfter(A_WEEK_OLD);
      }

      if (isToday(momentDate)) {
        return t?.("threads.last_chat_at_today", { time: momentDate })
      } else if (isYesterday(momentDate)) {
        return t?.("threads.last_chat_at_yesterday", { time: momentDate })
      } else if (isWithinAWeek(momentDate)) {
        return t?.("threads.last_chat_at_this_week", { time: momentDate })
      }
      return t?.("threads.last_chat_at_fallback", { time: momentDate })
    }

    const trimTitleIfDeleted = (title) => {
      const MAX_CHARS = 12
      if (title?.length > MAX_CHARS) {
        return `${title.slice(0, MAX_CHARS)}...`
      }
      return title
    }

    return (
      <Styles.Thread
        isDeleted={isThreadDeleted}
        mobile={mobile}
        selected={threadId === threadObj?.threadId}
        folderId={threadObj?.folderId}
        disableSelect={selectedAllThreads}
        newFromInboxAction={threadObj?.newFromInboxAction}
        onClick={(e) => {
          e.preventDefault()
          if (!selecting) {
            onChangeThreadId(threadObj?.threadId)
          } else {
            const selected = selectedThreads.find((st) => st === threadObj.threadId) || selectedAllThreads
            if (selectedAllThreads) {
              return
            }
            let array
            if (selected) {
              array = selectedThreads.filter(o => o !== threadObj.threadId)
            } else {
              if (selectedThreads?.length >= 100) {
                const alertSelectedThreadsCannotExceed100 = t("threads.alert_selected_threads_cannot_exceed_100")
                alert(alertSelectedThreadsCannotExceed100)
                return
              }
              array = [...selectedThreads, threadObj.threadId]
            }
            setSelectedThreads(array)
          }
        }}
      >
        {selecting && (
          <>
            {selectedThreads.find((st) => st === threadObj.threadId) || selectedAllThreads ?
              <FontAwesomeIcon
                className="checked-check-square-icon"
                icon={faSquareCheck}
              />
              :
              <FontAwesomeIcon
                className="check-square-icon"
                icon={faSquare}
              />
            }
          </>
        )}
        <IconContainer
          showGroupPlaceholderIcon={isGroup}
          url={threadObj.member?.inboxProfile?.profilePicUrl}
          size={mobile ? "SM" : "S"}
          name={renderUserNameInboxProfileFirst({
            externalProfile: threadObj?.member?.externalProfile,
            inboxProfile: threadObj?.member?.inboxProfile
          })}
        />
        <Styles.CustomerDetails isDeleted={isThreadDeleted} selecting={selecting} mobile={mobile}>
          <Flex sx={{ justifyContent: "space-between", alignItems: "center" }}>
            <div className="thread-title" title={threadObj?.title}>
              {isThreadDeleted ? `[deleted] ${trimTitleIfDeleted(threadObj?.title)}` : threadObj?.title}
            </div>
            {threadObj?.lastChatAt ?
              <>
                <div 
                  className="thread-time-display"
                  data-tooltip-id={threadObj?.threadId}
                  data-tooltip-content={t?.("threads.last_chat_at_fulltime_fallback", { time: moment(threadObj?.lastChatAt) })}
                >
                  {getLastChatTimeText(moment(threadObj?.lastChatAt))}
                </div>
                <ReactTooltip
                  id={threadObj?.threadId}
                  place="right"
                />
              </>
              :
              null
            }
          </Flex>
          <Flex
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="channel-name-wrapper" >
              <IntegrationIconDisplay
                icon={threadObj.wChannel?.integrationIcon}
                name={threadObj.wChannel?.integrationName}
                size="16px"
              />
              <div className="channel-name">{threadObj.wChannel?.name}</div>
            </div>
            {(threadObj?.unreadCount || 0) > 0 ?
              <div className="thread-unread-count">{threadObj.unreadCount > 99 ? "99+" : threadObj.unreadCount}</div>
              :
              null
            }
          </Flex>

          <div className="thread-last-chat">{threadObj?.lastMessage}</div>
        </Styles.CustomerDetails>
        <Styles.DesktopUIInbox>
          {!selecting ?
            (
              <Styles.MoveToButtonContainer className="short-cut-button">
                {/* <NewBoxButton
                  sx={{
                    minWidth: "28px",
                    minHeight: "28px",
                    borderRadius: 0,
                    borderBottomRightRadius: "8px",
                    ".icon": {
                      color: "#707070"
                    },
                    "&:hover": {
                      ".icon": {
                        color: "#707070"
                      }
                    }
                  }}
                  icon={faEllipsis}
                  borderless
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                  }}
                /> */}
                <NewBoxButton
                  sx={{
                    minWidth: "28px",
                    minHeight: "28px",
                    background: threadObj?.folderId === "done" ? "#6544f4" : "hsl(94, 48%, 56%)",
                    borderRadius: 0,
                    borderBottomRightRadius: "8px",
                    ".icon": {
                      color: "#ffffff"
                    },
                    "&:hover": {
                      background: threadObj?.folderId === "done" ? "#6544f4" : "hsl(94, 48%, 56%)",
                      ".icon": {
                        color: "#ffffff"
                      }
                    }
                  }}
                  icon={threadObj?.folderId === "done" ? faInbox : faCheck}
                  loading={isInFlightThreadFolder}
                  disabled={isInFlightThreadFolder}
                  borderless
                  data-tooltip-content={threadObj?.folderId === "done" ? t?.("threads.move_to_main") : t?.("threads.move_to_done")}
                  data-tooltip-id="thread-option-tooltip"
                  onClick={(e) => {
                    updateThreadFolder({
                      etag: threadObj.etag,
                      folderId: threadObj?.folderId === "done" ? "main" : "done",
                      selectedThreadId: threadObj?.threadId
                    })
                    e.stopPropagation()
                    e.preventDefault()
                  }}
                />
              </Styles.MoveToButtonContainer>
            ) : null
          }
        </Styles.DesktopUIInbox>
      </Styles.Thread>
    )
  }

  render() {
    const {
      threads,
      location,
      query,
      mobile,
      setTempDeletedThreads,
      updateThreadFolder,
      hasNext,
    } = this.props.data
    const {
      index,
      style,
    } = this.props
    const isItemLoaded = !hasNext || index < threads?.length
    if (!isItemLoaded) {
      return <Loader mobile={mobile} style={style} />
    }
    const threadObj = threads?.[index]?.node

    const t = this.props.t
    return (
      <Styles.StyledLink
        to={{
          ...location,
          search: qs.stringify({
            ...query ?? {},
            thread: threadObj?.threadId
          })
        }}
        style={{
          ...style,
          left: style.left + GUTTER_SIZE,
          top: style.top + GUTTER_SIZE,
          width: `calc(100% - ${GUTTER_SIZE * 2}px)`,
          height: style.height - GUTTER_SIZE,
          overflow: "hidden",
        }}
        onClick={(e) => {
          e.preventDefault()
        }}
      >
        {mobile ? (
          <SwipeToDelete
            onDelete={() => {
              setTempDeletedThreads((t) => {
                return [...t, threadObj.threadId]
              })
              updateThreadFolder({
                etag: threadObj.etag,
                folderId: threadObj?.folderId === "done" ? "main" : "done",
                selectedThreadId: threadObj?.threadId
              })
            }}
            height={mobile ? 92 : 84}
            deleteColor={threadObj?.folderId === "done" ? "#0084FF" : "hsl(94, 48%, 56%)"}
            deleteText={(
              <div>
                <FontAwesomeIcon icon={threadObj?.folderId === "done" ? faInbox : faCheck} />
                <div
                  sx={{
                    fontSize: "12px"
                  }}
                >{threadObj?.folderId === "done" ? "Main" : "Done"}</div>
              </div>
            )}
            disabled={!mobile}
            id={threadObj?.threadId}
            className="swipe-to-delete"
          >
            {this.renderThread({ threadObj, t })}
          </SwipeToDelete>
        ) : this.renderThread({ threadObj, t })}
      </Styles.StyledLink>
    )
  }
}

const RowWithTranslatedLastChatAt = withTranslation("common", { withRef: true })(Row)

const Threads = ({
  channelIds,
  folderName,
  folderId,
  search,
  onChangeThreadId,
  selecting,
  isFilteringUnread,
  onFinishUpdateThreadsFolder,
  threadId,
  mobile = false,
  isInBackground,
  isGlobalThreadsOpen,
  setFolderId,
  setSearch,
  setFilter,
  setChannelIdsFilter,
  ...props
}) => {
  const { isSuperAdmin, isSuperAuditor } = useContext(UserContext)
  const { t } = useTranslation("common")
  const { location } = useHistory()
  const query = qs.parse(location.search)
  const [selectedThreads, setSelectedThreads] = useState([])
  const [tempDeletedThreads, setTempDeletedThreads] = useState([])
  const [selectedAllThreads, setSelectedAllThreads] = useState(false)
  const [showMoveFolderDropDown, setShowMoveFolderDropDown] = useState(false)
  const [showRefetchOverlay, setShowRefetchOverlay] = useState(false)
  const firstLoad = useRef(true)
  // const firstLoadForInfiniteLoader = useRef(true)
  const infiniteLoaderRef = useRef(null)
  const [loading, startTransition] = useTransition()

  const [commitThreadFolder, isInFlightThreadFolder] = useMutation(
    ThreadsUpdateThreadFolderMutation
  )

  useEffect(() => {
    setSelectedThreads([])
    setTempDeletedThreads([])
    setSelectedAllThreads(false)
  }, [folderId, isFilteringUnread, selecting])

  const {
    data,
    loadNext,
    hasNext,
    isLoadingNext,
    refetch
  } = usePaginationFragment(
    graphql`
      fragment Threads_userViewer on UserScope
      @argumentDefinitions(
        search: { type: "String", defaultValue: "" }
        folderId: { type: "ID", defaultValue: "main" }
        wChannelIds: { type: "[ID]" }
        first: { type: "Int", defaultValue: 30 }
        after: { type: "String" }
        assigned: { type: "Boolean", defaultValue: false }
        onlyUnread: { type: "Boolean", defaultValue: false }
      )
      @refetchable(queryName: "ThreadsPaginateQuery") {
        role
        threads (
          search: $search
          folderIds: [$folderId]
          wChannelIds: $wChannelIds
          first: $first
          after: $after
          assigned: $assigned
          onlyUnread: $onlyUnread
        ) @connection(
          key: "Threads_threads"
          filters: ["search", "folderIds", "wChannelIds", "assigned", "onlyUnread"]
        ) {
          edges {
            node {
              threadId
              folderId
              folder {
                folderId
                name
                color
              }
              wMemberId
              wChannelId
              wChannel {
                name
                integrationIcon
                integrationName
              }
              etag
              title
              updatedAt
              lastChatAt
              lastMessage
              lastReadAt
              unreadCount
              member {
                groupChat
                meta
                externalId
                externalProfile
                inboxProfile {
                  firstName
                  lastName
                  name
                  profilePicUrl
                }
              }
              newFromInboxAction
            }
          }
          pageInfo {
            totalCount
          }
        }
      }
    `,
    props.data.userViewer
  )

  const debounceSearch = useCallback(_.debounce(({
    search,
    folderId,
    channelIds,
    isFilteringUnread,
  }) => {
    startTransition(() => {
      const params = {
        search,
        folderId,
        assigned: folderId === FOLLOW_UP_TEMP_FOLDER_ID ? true : false,
        onlyUnread: !!isFilteringUnread
      }
      if (channelIds?.length) {
        params.wChannelIds = channelIds
      }
      refetch(params, {
        fetchPolicy: "network-only",
      })
    })
  }, 1000), [])

  useEffect(() => {
    if (firstLoad.current && folderId === "main") {
      firstLoad.current = false
    } else if (!isInBackground) {
      if (search) {
        debounceSearch({
          search,
          folderId,
          channelIds,
          isFilteringUnread,
          refetch,
        })
      } else {
        startTransition(() => {
          const params = {
            search,
            folderId,
            assigned: folderId === FOLLOW_UP_TEMP_FOLDER_ID ? true : false,
            onlyUnread: !!isFilteringUnread
          }
          if (channelIds?.length) {
            params.wChannelIds = channelIds
          }
          refetch(params, {
            fetchPolicy: "network-only",
          })
        })
      }
      if (infiniteLoaderRef.current) {
        infiniteLoaderRef.current.resetloadMoreItemsCache()
      }
    }
  }, [search, folderId, JSON.stringify(channelIds), isFilteringUnread, isInBackground])

  const updateThreadFolder = ({ folderId, etag, selectedThreadId }) => {
    let clientMutationId = 0
    let variables = {
      threadId: selectedThreadId,
      folderId,
      clientMutationId: clientMutationId++,
      etag,
    }
    const mutationParams = {
      variables: {
        input: variables,
      },
      onCompleted: (response, errs) => {
        if (!errs) {
          setTimeout(() => {
            setTempDeletedThreads((t) => t.filter(o => o !== selectedThreadId))
          }, 0)
        }
      },
      onError: (err) => {
        const errorMsg = err.res?.json?.errors[0]?.message || err
        alert(`Cannot move to ${_.startCase(folderId)} folder by following reason: ${errorMsg}\nPlease try again later.`)
        setTempDeletedThreads((t) => t.filter(o => o !== selectedThreadId))
      }
    }
    commitThreadFolder(mutationParams)
  }

  const memoizedValue = useMemo(() => {
    if (folderId && !isInBackground) {
      const isFollowUp = folderId === FOLLOW_UP_TEMP_FOLDER_ID ? true : false
      return {
        variables: {
          search,
          folderId,
          wChannelIds: channelIds,
          assigned: isFollowUp,
          onlyUnread: !!isFilteringUnread,
        },
        subscription: ThreadSubscription,
        updater: (store, data) => {
          let variables = {
            search: search || "",
            folderIds: folderId ? [folderId] : ["main"],
            assigned: isFollowUp,
            onlyUnread: !!isFilteringUnread,
          }
          if (channelIds?.length) {
            variables.wChannelIds = channelIds
          }
          const userViewer = store.getRoot().getLinkedRecord("userViewer")
          const threadsConnection = ConnectionHandler.getConnection(
            userViewer,
            "Threads_threads",
            variables
          )
          const threadSubscription = store.getRootField("thread")
          const newThread = threadSubscription.getLinkedRecord("thread")
          const newAction = threadSubscription.getValue("action")
          // const oldFolderId = newThread.getValue("folderId")
          // console.log("newAction", newAction)
          if (newAction === "REMOVE" && threadsConnection) {
            ConnectionHandler.deleteNode(threadsConnection, newThread.getDataID())
          } else if (newAction === "BATCH_UPDATE") {
            setShowRefetchOverlay(true)
          } else if (newAction === "PUSH" && threadsConnection) {
            const edge = ConnectionHandler.createEdge(store, threadsConnection, newThread, "ThreadEdge")
            ConnectionHandler.deleteNode(threadsConnection, newThread.getDataID())
            ConnectionHandler.insertEdgeBefore(threadsConnection, edge)
          } else if (newAction === "ADD" && threadsConnection) {
            newThread.setValue(true, "newFromInboxAction")
            const edge = ConnectionHandler.createEdge(store, threadsConnection, newThread, "ThreadEdge")
            ConnectionHandler.deleteNode(threadsConnection, newThread.getDataID())
            ConnectionHandler.insertEdgeBefore(threadsConnection, edge)
          }
        }
      }
    }
    return {
      variables: {},
      subscription: ThreadSubscription
    }
  }, [folderId, JSON.stringify(channelIds), search, isFilteringUnread, isInBackground])

  useSubscription(memoizedValue)

  const totalCount = data?.threads?.pageInfo?.totalCount

  let threads = _.cloneDeep(data?.threads?.edges) || []
  if (tempDeletedThreads?.length) {
    threads = threads.filter((o) => !tempDeletedThreads.includes(o.node.threadId))
  }
  let selectedThreadsCount
  if (selectedThreads?.length) {
    selectedThreadsCount = selectedThreads?.length
  } else if (selectedAllThreads) {
    selectedThreadsCount = totalCount
  } else {
    selectedThreadsCount = "No"
  }

  let disableMoveToButton = true
  if (selectedThreads?.length) {
    disableMoveToButton = false
  }
  if (selectedAllThreads) {
    disableMoveToButton = false
  }

  let selectedThreadText
  const threads_selected = t("threads.threads_selected", { count: selectedThreadsCount })
  const threads_selected_zero = t("threads.threads_selected_zero")

  if (_.isNumber(selectedThreadsCount) && selectedThreadsCount > 1) {
    selectedThreadText = threads_selected
  } else if (_.isNumber(selectedThreadsCount) && selectedThreadsCount === 1) {
    selectedThreadText = threads_selected
  } else  {
    selectedThreadText = threads_selected_zero
  }

  const isItemLoaded = (index) => !hasNext || index < threads?.length
  const itemCount = hasNext ? threads.length + 1 : threads.length
  const loadMoreItems = (startIndex, stopIndex) => {
    if (isLoadingNext) return null
    return new Promise(resolve => {
      loadNext(minimumBatchSize, {
        onComplete: (err) => {
          if (!err) {
            resolve()
          }
        }
      })
    })
  }

  return (
    <>
      <Styles.ThreadsContainer className="threads-container threads" selecting={selecting}>
        {loading ?
          <div style={{ height: "440px", width: "100%", background: "#F0F2F7", paddingTop: "8px" }}>
            <Loader mobile={mobile} />
            <Loader mobile={mobile} />
            <Loader mobile={mobile} />
            <Loader mobile={mobile} />
            <Loader mobile={mobile} />
          </div>
          :
          <>
            {showRefetchOverlay &&
              <Styles.ThreadsOverlay className="thread-overlay">
                <div className="overlay-content">
                  <Text className="overlay-text" pb={2} sx={{ fontSize: 1 }}>{t?.("threads.moved_to_another_folder")}</Text>
                  <NewBoxButton
                    className="reload-button"
                    primary
                    text={t?.("threads.reload_button")}
                    icon={faRotateRight}
                    onClick={() => {
                      startTransition(() => {
                        const params = {
                          search,
                          folderId,
                          assigned: folderId === FOLLOW_UP_TEMP_FOLDER_ID ? true : false,
                          onlyUnread: !!isFilteringUnread
                        }
                        if (channelIds?.length) {
                          params.wChannelIds = channelIds
                        }
                        refetch(params, {
                          fetchPolicy: "network-only",
                        })
                      })
                      setShowRefetchOverlay(false)
                    }}
                  />
                </div>
                
              </Styles.ThreadsOverlay>
            }
            {selecting &&
              <Styles.ThreadsManageContainer>
                <Flex sx={{ justifyContent: "center" }}>
                  <Styles.CustomPopperContainer
                    className="custom-popper-container"
                    show={showMoveFolderDropDown}
                    display={(
                      <NewBoxButton
                        color="#405DE6"
                        className="count-select-button"
                        primary
                        disabled={disableMoveToButton || isSuperAuditor || isSuperAdmin}
                        icon={selectedThreadsCount >= 1 && faCheck}
                        text={selectedThreadText}
                        onClick={() => {
                          if (selectedThreads?.length || selectedAllThreads) {
                            setShowMoveFolderDropDown(true)
                          }
                        }}
                      />
                    )}
                    placement="right-start"
                    onClickOutside={() => {
                      if (showMoveFolderDropDown) {
                        setShowMoveFolderDropDown(false)
                      }
                    }}
                  >
                    {() => (
                      <ManageThreadsPopper
                        setFolderId={setFolderId}
                        allThreadsSelected={selectedAllThreads}
                        search={search}
                        threads={threads}
                        selectedThreadCount={selectedThreads?.length}
                        selectedThreadCountAll={totalCount}
                        selectedAllThreads={selectedAllThreads}
                        selectedThreads={selectedThreads}
                        currentFolder={folderId}
                        currentChannelIds={channelIds}
                        role={data?.role}
                        onSuccess={() => {
                          setShowMoveFolderDropDown(false)
                          onFinishUpdateThreadsFolder(true)
                          setSelectedThreads([])
                          setSelectedAllThreads(false)
                        }}
                        setSearch={setSearch}
                        setFilter={setFilter}
                        setChannelIdsFilter={ setChannelIdsFilter}
                      />
                    )}
                  </Styles.CustomPopperContainer>
                </Flex>
                {data?.role === "ADMIN" || data?.role === "SUPER_ADMIN" ? (
                  <Flex sx={{ justifyContent: "center" }}>
                    <NewBoxButton
                      className="select-all-button"
                      borderless
                      primary
                      disabled={totalCount <= 0}
                      text={selectedAllThreads ? t?.("threads.unselect_all_threads", { count: totalCount, folderName }) : t?.("threads.select_all_threads", { count: totalCount, folderName })}
                      onClick={() => {
                        setSelectedAllThreads(!selectedAllThreads)
                        setSelectedThreads([])
                      }}
                    />
                  </Flex>
                ) : null}
              </Styles.ThreadsManageContainer>
            }
            <AutoSizer>
              {({ height, width }) => (
                <InfiniteLoader
                  ref={infiniteLoaderRef}
                  isItemLoaded={isItemLoaded}
                  itemCount={itemCount}
                  loadMoreItems={loadMoreItems}
                  minimumBatchSize={minimumBatchSize}
                  threshold={5}
                >
                  {({ onItemsRendered, ref }) => (
                    <List
                      height={height}
                      itemCount={itemCount}
                      innerElementType={innerElementType}
                      itemSize={mobile ? 100 : 92}
                      itemData={{
                        threads,
                        location,
                        query,
                        mobile,
                        setTempDeletedThreads,
                        updateThreadFolder,
                        threadId,
                        selectedAllThreads,
                        selecting,
                        onChangeThreadId,
                        selectedThreads,
                        setSelectedThreads,
                        isInFlightThreadFolder,
                        hasNext,
                      }}
                      onItemsRendered={onItemsRendered}
                      ref={ref}
                      width={width}
                    >
                      {RowWithTranslatedLastChatAt}
                    </List>
                  )}
                </InfiniteLoader>
              )}
            </AutoSizer>
            {!threads?.length && (
              <Styles.NoDataContainer>{t?.("threads.no_messages_now_part_1")}<br /><br />{t?.("threads.no_messages_now_part_2")}</Styles.NoDataContainer>
            )}
          </>
        }
        <ReactTooltip
          id="thread-option-tooltip"
          place="right"
        />
      </Styles.ThreadsContainer>
    </>
  )
}

export default Threads
