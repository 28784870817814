/**
 * @generated SignedSource<<65e5a3f32f88bf40515213864cda520e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "wAppId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 30
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SettingsModalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserScope",
        "kind": "LinkedField",
        "name": "userViewer",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Literal",
                "name": "isOpenSettingsAgents",
                "value": true
              },
              {
                "kind": "Literal",
                "name": "isOpenSettingsChannels",
                "value": false
              },
              {
                "kind": "Literal",
                "name": "isOpenSettingsOrganizationConfig",
                "value": false
              },
              {
                "kind": "Literal",
                "name": "isOpenSettingsProfile",
                "value": true
              },
              {
                "kind": "Literal",
                "name": "isOpenSettingsUsers",
                "value": false
              }
            ],
            "kind": "FragmentSpread",
            "name": "SettingsModal_userViewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SettingsModalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserScope",
        "kind": "LinkedField",
        "name": "userViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "profilePicUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "initialRole",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "wUserId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "App",
            "kind": "LinkedField",
            "name": "app",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "AppConnection",
            "kind": "LinkedField",
            "name": "appsPaginated",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AppEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "App",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v0/*: any*/),
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "appsPaginated(first:30)"
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "SettingsOrganizationPage_appsPaginated",
            "kind": "LinkedHandle",
            "name": "appsPaginated"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "99758a1fb3a9e5c5123ff64387da3a9f",
    "id": null,
    "metadata": {},
    "name": "SettingsModalQuery",
    "operationKind": "query",
    "text": "query SettingsModalQuery {\n  userViewer {\n    ...SettingsModal_userViewer_3iVTXT\n    id\n  }\n}\n\nfragment SettingsAgentsContainer_userViewer_naICF on UserScope {\n  id\n}\n\nfragment SettingsModal_userViewer_3iVTXT on UserScope {\n  email\n  fullName\n  firstName\n  lastName\n  profilePicUrl\n  role\n  initialRole\n  wUserId\n  app {\n    wAppId\n    name\n    id\n  }\n  ...SettingsAgentsContainer_userViewer_naICF\n  ...SettingsProfileContainer_userViewer_naICF\n  ...SettingsOrganizationPage_userViewer\n  id\n}\n\nfragment SettingsOrganizationPage_userViewer on UserScope {\n  appsPaginated(first: 30) {\n    edges {\n      node {\n        id\n        wAppId\n        name\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n\nfragment SettingsProfileContainer_userViewer_naICF on UserScope {\n  id\n}\n"
  }
};
})();

node.hash = "d58522d7f53f56cfbc91138363cb3bf9";

module.exports = node;
