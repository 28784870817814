/** @jsxImportSource theme-ui */

import React, { useEffect, useState, useTransition, useContext, useRef } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Flex } from "theme-ui"
import qs from "query-string"
import { useTranslation } from "react-i18next"
import {
  faRightFromBracket,
  faTimes,
} from "@fortawesome/free-solid-svg-icons"
import {
  useQueryLoader,
  usePreloadedQuery,
  useRefetchableFragment,
  useMutation,
} from "react-relay"
import graphql from "babel-plugin-relay/macro"
import { useHistory } from "yarr"

import Tab from "../Radiate/Tab/Tab"
import NewBoxButton from "../Radiate/NewBoxButton/NewBoxButton"
import IconContainer from "../IconContainer/IconContainer"
import SettingsUsersContainer from "./SettingsUsersContainer"
import SettingsAgentsContainer from "./SettingsAgentsContainer"
import SettingsChannelsContainer from "./SettingsChannelsContainer"
import SettingsProfileContainer from "./SettingsProfileContainer"
import SettingsOrganizationPage from "./SettingsOrganizationPage"
import SettingsAccount from "./SettingsAccount"
import SimpleMobileNavigation from "../SimpleMobileNavigation/SimpleMobileNavigation"
import { LoadingMask } from "../../CommonStyles"
import * as Styles from "./SettingsModalStyles"
import {
  InitializeLogoutMutation,
} from "./SettingsModalQuery"

import { Overlay } from "../../pages/Inbox/InboxStyles"
import { UserContext } from "../../contexts/UserContext"
import SettingsOrganizationConfig from "./SettingsOrganizationConfig"

const OIDC_ISSUER = process.env.REACT_APP_OIDC_ISSUER
const OIDC_CLIENT_ID = process.env.REACT_APP_OIDC_CLIENT_ID
const OWN_DOMAIN = process.env.REACT_APP_OWN_DOMAIN
const PUBLIC_URL = process.env.PUBLIC_URL

function getNameDisplay(data) {
  let name
  if (data.fullName) {
    name = data.fullName
  } else if (data.firstName && data.lastName) {
    name = `${data.firstName} ${data.lastName}`
  } else if (data.firstName) {
    name = data.firstName
  } else {
    name = data.email
  }
  return name
}

const SettingsModalQuery = graphql`
  query SettingsModalQuery {
    userViewer {
      ...SettingsModal_userViewer @arguments( isOpenSettingsUsers: false, isOpenSettingsAgents: true, isOpenSettingsChannels: false, isOpenSettingsProfile: true, isOpenSettingsOrganizationConfig: false )
    }
  }
`

const InnerContainer = ({
  queryRef,
  ...props
}) => {
  const data = usePreloadedQuery(
    SettingsModalQuery,
    queryRef,
  )
  return (
    <InnerSettingsModal
      data={data}
      {...props}
    />
  )
}

const InnerSettingsModal = ({
  queryRef,
  onClose,
  open,
  mobile = false,
  standalone = false,
  ...props
}) => {
  const { isSuperAuditor } = useContext(UserContext)
  const [
    data,
    refetch
  ] = useRefetchableFragment(
    graphql`
      fragment SettingsModal_userViewer on UserScope
      @argumentDefinitions(
        isOpenSettingsUsers:  { type: "Boolean!" }
        isOpenSettingsAgents:  { type: "Boolean!" }
        isOpenSettingsChannels:  { type: "Boolean!" }
        isOpenSettingsProfile:  { type: "Boolean!" }
        isOpenSettingsOrganizationConfig: { type: "Boolean!"}
      )
      @refetchable(queryName: "SettingsModalRefetchQuery") {
        email
        fullName
        firstName
        lastName
        profilePicUrl
        role
        initialRole
        wUserId
        app {
          wAppId
          name
        }
        ...SettingsAgentsContainer_userViewer @arguments(hasWChannelId: false, wChannelId: "") @include(if: $isOpenSettingsAgents)
        ...SettingsUsersContainer_userViewer @include(if: $isOpenSettingsUsers)
        ...SettingsChannelsContainer_userViewer @include(if: $isOpenSettingsChannels)
        ...SettingsProfileContainer_userViewer @arguments(hasWChannelId: false, wChannelId: "")  @include(if: $isOpenSettingsProfile)
        ...SettingsOrganizationPage_userViewer
        ...SettingsOrganizationConfig_userViewer @include(if: $isOpenSettingsOrganizationConfig)
      }
    `,
    props.data.userViewer
  )

  const { t } = useTranslation(["settings", "common"])
  const { location } = useHistory()
  const query = qs.parse(location.search)
  const embedded = query.embed === "woztell"
  const [loading, startTransition] = useTransition()
  const firstLoaded = useRef(false)
  const [openingTab, setOpeningTab] = useState("profile")
  const [initializeLogout] = useMutation(InitializeLogoutMutation)
  // const [logout, LoggingOut] = useMutation(LogoutMutation)

  const refetchData = () => {
    startTransition(() => {
      refetch({
        isOpenSettingsUsers: openingTab === "users",
        isOpenSettingsAgents: openingTab === "agents",
        isOpenSettingsChannels: openingTab === "channels",
        isOpenSettingsProfile: openingTab === "profile",
        isOpenSettingsAccount: openingTab === "account",
        isOpenSettingsOrganizationConfig: openingTab === "organization-config",
      }, {
        fetchPolicy: "network-only",
      })
    })
  }
  useEffect(() => {
    if (!firstLoaded.current) {
      firstLoaded.current = true
    } else {
      refetchData()
    }
  }, [openingTab])

  const isSuperAdminButNotAppUser = data?.initialRole === "SUPER_ADMIN" && data?.role === "SUPER_ADMIN"
  const isUser = data?.initialRole !== "SUPER_ADMIN" && data?.role === "USER"

  const loadingUI = (
    <div style={{ height: "100%", width: "100%", background: "#ffffff" }}>
      <LoadingMask style={{ height: "100%", width: "100%", background: "#ffffff" }}>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </LoadingMask>
    </div>
  )

  const profileContent = (
    <SettingsProfileContainer
      mobile={mobile}
      data={data?.__fragments?.SettingsProfileContainer_userViewer ? data : null}
    />
  )
  const agentsContent = (
    <SettingsAgentsContainer
      mobile={mobile}
      data={data?.__fragments?.SettingsAgentsContainer_userViewer ? data : null}
      ownUserId={data?.wUserId}
      isAdmin={data?.role === "ADMIN"}
      isInitialAdmin={data?.initialRole === "ADMIN"}
      isInitialSuperAdmin={data?.initialRole === "SUPER_ADMIN"}
    />
  )
  const accountContent = (
    <SettingsAccount
      data={data?.__fragments?.SettingsAccount_userViewer ? data : null}
      account={{
        profilePicUrl: data?.profilePicUrl,
        name: getNameDisplay(data),
        email: data?.email,
      }}
      currentWAppId={data?.app?.wAppId}
    />
  )
  const usersContent = (
    <SettingsUsersContainer
      mobile={mobile}
      data={data?.__fragments?.SettingsUsersContainer_userViewer ? data : null}
      ownUserId={data?.wUserId}
      isAdmin={data?.role === "ADMIN"}
      isInitialAdmin={data?.initialRole === "ADMIN"}
      isInitialSuperAdmin={data?.initialRole === "SUPER_ADMIN"}
    />
  )
  const channelsContent = (
    <SettingsChannelsContainer
      mobile={mobile}
      data={data?.__fragments?.SettingsChannelsContainer_userViewer ? data : null}
    />
  )
  const organizationConfigContent = (
    <SettingsOrganizationConfig
      mobile={mobile}
      data={data?.__fragments?.SettingsOrganizationConfig_userViewer ? data : null}
    />
  )

  const profileTranslation = t?.("tabs.profile")
  const agentsTranslation = t?.("tabs.agents")
  const usersTranslation = t?.("tabs.users")
  const channelsTranslation = t?.("tabs.channels")
  const accountTranslation = t?.("tabs.account")
  const organizationConfigTranslation = t?.("tabs.organization_config")

  let tabsList = [
    {
      id: "profile",
      text: profileTranslation,
      content: loading || !data?.__fragments?.SettingsProfileContainer_userViewer ?
        loadingUI : profileContent
    }, {
      id: "agents",
      text: agentsTranslation,
      content: loading || !data?.__fragments?.SettingsAgentsContainer_userViewer ?
        loadingUI : agentsContent
    }, {
      id: "users",
      text: usersTranslation,
      content: loading || !data?.__fragments?.SettingsUsersContainer_userViewer ?
        loadingUI : usersContent
    }, {
      id: "channels",
      text: channelsTranslation,
      content: loading || !data?.__fragments?.SettingsChannelsContainer_userViewer ?
        loadingUI : channelsContent
    }, {
      id: "organization-config",
      text: organizationConfigTranslation,
      content: loading || !data?.__fragments?.SettingsOrganizationConfig_userViewer ?
        loadingUI : organizationConfigContent
    }
  ]
  if (isSuperAdminButNotAppUser || isSuperAuditor) {
    tabsList = [
      {
        id: "agents",
        text: agentsTranslation,
        content: loading || !data?.__fragments?.SettingsAgentsContainer_userViewer ?
          loadingUI : agentsContent
      }, {
        id: "users",
        text: usersTranslation,
        content: loading || !data?.__fragments?.SettingsUsersContainer_userViewer ?
          loadingUI : usersContent
      }, {
        id: "channels",
        text: channelsTranslation,
        content: loading || !data?.__fragments?.SettingsChannelsContainer_userViewer ?
          loadingUI : channelsContent
      }, {
        id: "organization-config",
        text: organizationConfigTranslation,
        content: loading || !data?.__fragments?.SettingsOrganizationConfig_userViewer ?
          loadingUI : organizationConfigContent
      }
    ]
  }
  if (isUser) {
    tabsList = [
      {
        id: "profile",
        text: profileTranslation,
        content: loading || !data?.__fragments?.SettingsProfileContainer_userViewer ?
          loadingUI : profileContent
      }, {
        id: "agents",
        text: agentsTranslation,
        content: loading || !data?.__fragments?.SettingsAgentsContainer_userViewer ?
          loadingUI : agentsContent
      }
    ]
  }
  if (!embedded) {
    tabsList.push({
      id: "account",
      text: accountTranslation,
      content: loading ?
        loadingUI : accountContent
    })
  }

  
  let selectedTabIndex = 0
  if (isSuperAdminButNotAppUser || isSuperAuditor) {
    switch (openingTab) {
      case "agents":
        selectedTabIndex = 0
        break;
      case "users":
        selectedTabIndex = 1
        break;
      case "channels":
        selectedTabIndex = 2
        break;
      case "organization-config":
        selectedTabIndex = 3
        break;
      case "account":
        selectedTabIndex = 4
        break;
      default:
        break;
    }
  } else if (isUser) {
    switch (openingTab) {
      case "profile":
        selectedTabIndex = 0
        break;
      case "agents":
        selectedTabIndex = 1
        break;
      case "account":
        selectedTabIndex = 2
        break;
      default:
        break;
    }
  } else {
    switch (openingTab) {
      case "profile":
        selectedTabIndex = 0
        break;
      case "agents":
        selectedTabIndex = 1
        break;
      case "users":
        selectedTabIndex = 2
        break;
      case "channels":
        selectedTabIndex = 3
        break;
      case "organization-config":
        selectedTabIndex = 4
        break;
      case "account":
        selectedTabIndex = 5
        break;
      default:
        break;
    }
  }

  if (mobile) {
    const name = getNameDisplay(data)
    const accountDisplay = (
      <div
        sx={{
          p: "16px",
          display: "flex",
          alignItems: "center"
        }}
      >
        <IconContainer
          url={data?.profilePicUrl}
          size="M"
          name={name}
          sx={{
            mr: "16px",
          }}
        />
        <div>
          <div>{name}</div>
          {name === data.email ?
            null
            :
            <div>{data.email}</div>
          }
        </div>
      </div>
    )

    const logoutButton = (
      <div
        sx={{
          height: "44px",
          display: "flex",
          justifyContent: "center",
          background: "white",
          alignItems: "center",
          color: "danger",
          cursor: "pointer",
          "&:active": {
            background: "#ddd",
          }
        }}
        onClick={() => {
          initializeLogout({
            onCompleted: async (response) => {
              if (response?.initializeLogout?.ok) {
                const { token, state } = response.initializeLogout
                const { end_session_endpoint } = await fetch(`${OIDC_ISSUER}/.well-known/openid-configuration`).then((res) => res.json())
                const params = {
                  id_token_hint: token,
                  client_id: OIDC_CLIENT_ID,
                  state,
                  post_logout_redirect_uri: `${OWN_DOMAIN}${PUBLIC_URL}/logout_redirect/`
                }
                window.location.href = `${end_session_endpoint}?${qs.stringify(params)}`
              } 
            }
          })
        }}
      >
        {t?.("settings_modal.logout")}
      </div>
    )

    const mobileSections = [{
      id: "section1",
      items: ["account", "org"]
    }, 
    (!isSuperAdminButNotAppUser && !isSuperAuditor) && {
      id: "section2",
      items: ["agent_profile"]
    }
    ]

    if (!embedded) {
      mobileSections.push({
        id: "section3",
        items: ["logout"]
      })
    }

    return (
      <>
        <Overlay
          show={open}
        />
        <Styles.SettingsModalContainer
          mobile={mobile}
          show={open}
          className="settings-modal-container"
        >
          <SimpleMobileNavigation
            title={t?.("settings_modal.settings")}
            topRightComponent={(
              <FontAwesomeIcon
                className="close-button"
                sx={{
                  width: "20px",
                  height: "20px",
                  fontSize: "24px",
                }}
                icon={faTimes}
                onClick={() => {
                  onClose()
                }}
              />
            )}
            sections={mobileSections}
            items={[{
              id: "account",
              custom: accountDisplay,
            }, {
              id: "org",
              title: t?.("settings_modal.organization"),
              value: data?.app?.name,
              navTo: {
                component: ({ navBack }) => (
                  <SettingsOrganizationPage
                    navBack={navBack}
                    currentWAppId={data?.app?.wAppId}
                    data={data?.__fragments?.SettingsOrganizationPage_userViewer ? data : null}
                  />
                )
              }
            }, 
            (!isSuperAdminButNotAppUser && !isSuperAuditor) && {
              id: "agent_profile",
              title: t?.("settings_modal.agent_profile"),
              navTo: {
                component: () => profileContent
              }
            },
            //  {
            //   id: "channels",
            //   title: "Channels",
            //   navTo: {
            //     component: () => channelsContent
            //   }
            // },
            {
              id: "logout",
              custom: logoutButton
            }]}
          />
        </Styles.SettingsModalContainer>
      </>
    )
  }
  
  return (
    <Styles.SettingsModalContainer className="settings-modal-container">
      <Flex px={4} py={4} sx={{ alignItems: "center", justifyContent: "space-between" }}>
        <div className="modal-title">{t?.("settings_modal.settings")}</div>
        <FontAwesomeIcon
          className="close-button"
          icon={faTimes}
          onClick={() => {
            onClose()
          }}
        />
      </Flex>
      <Tab
        className="settings-tab-container"
        newTheme
        actions={!embedded ? [(
          <NewBoxButton
            borderless
            text="Logout"
            rightIcon={faRightFromBracket}
            sx={{
              fontSize: "0.9rem",
            }}
            onClick={() => {
              initializeLogout({
                onCompleted: async (response) => {
                  if (response?.initializeLogout?.ok) {
                    const { token, state } = response.initializeLogout
                    const { end_session_endpoint } = await fetch(`${OIDC_ISSUER}/.well-known/openid-configuration`).then((res) => res.json())
                    const params = {
                      id_token_hint: token,
                      client_id: OIDC_CLIENT_ID,
                      state,
                      post_logout_redirect_uri: `${OWN_DOMAIN}${PUBLIC_URL}/logout_redirect/`
                    }
                    window.location.href = `${end_session_endpoint}?${qs.stringify(params)}`
                  }
                }
              })
            }}
          />
        )] : null}
        selectedTab={selectedTabIndex}
        onSelectTab={(toIndex) => {
          if (isSuperAuditor) {
            switch (toIndex) {
              case 0:
                setOpeningTab("agents")
                break;
              case 1:
                setOpeningTab("users")
                break;
              case 2:
                setOpeningTab("channels")
                break;
              case 3:
                setOpeningTab("organization-config")
                break;
              default:
                break;
            }
          } else if (data?.initialRole === "SUPER_ADMIN" && data?.role === "SUPER_ADMIN") {
            switch (toIndex) {
              case 0:
                setOpeningTab("agents")
                break;
              case 1:
                setOpeningTab("users")
                break;
              case 2:
                setOpeningTab("channels")
                break;
              case 3:
                setOpeningTab("organization-config")
                break;
              case 4:
                setOpeningTab("account")
                break;
              default:
                break;
            }
          } else if (isUser) {
            switch (toIndex) {
              case 0:
                setOpeningTab("profile")
                break;
              case 1:
                setOpeningTab("agents")
                break;
              case 2:
                setOpeningTab("account")
                break;
              default:
                break;
            }
          } else {
            switch (toIndex) {
              case 0:
                setOpeningTab("profile")
                break;
              case 1:
                setOpeningTab("agents")
                break;
              case 2:
                setOpeningTab("users")
                break;
              case 3:
                setOpeningTab("channels")
                break;
              case 4:
                setOpeningTab("organization-config")
                break;
              case 5:
                setOpeningTab("account")
                break;
              default:
                break;
            }
          }
        }}
        tabs={tabsList}
      />
    </Styles.SettingsModalContainer>
  )
}

const SettingsModal = (props) => {
  const [
    queryReference,
    loadQuery,
  ] = useQueryLoader(
    SettingsModalQuery,
    props.initialQueryRef, /* e.g. provided by router */
  )

  useEffect(() => {
    loadQuery({})
  }, [])

  if (queryReference) {
    return (
      <InnerContainer
        queryRef={queryReference}
        {...props}
      />
    )
  }
}

export default SettingsModal
