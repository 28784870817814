/**
 * @generated SignedSource<<494230641a97dd6a655d57d70e5020f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "assigned"
},
v2 = {
  "defaultValue": 30,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": "main",
  "kind": "LocalArgument",
  "name": "folderId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v5 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "onlyUnread"
},
v6 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "search"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "wChannelIds"
},
v8 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v9 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v10 = {
  "kind": "Variable",
  "name": "assigned",
  "variableName": "assigned"
},
v11 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v12 = {
  "kind": "Variable",
  "name": "onlyUnread",
  "variableName": "onlyUnread"
},
v13 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v14 = {
  "kind": "Variable",
  "name": "wChannelIds",
  "variableName": "wChannelIds"
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v17 = [
  (v9/*: any*/),
  (v10/*: any*/),
  (v11/*: any*/),
  {
    "items": [
      {
        "kind": "Variable",
        "name": "folderIds.0",
        "variableName": "folderId"
      }
    ],
    "kind": "ListValue",
    "name": "folderIds"
  },
  (v12/*: any*/),
  (v13/*: any*/),
  (v14/*: any*/)
],
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "folderId",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ThreadsPaginateQuery",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": [
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "kind": "Variable",
                "name": "folderId",
                "variableName": "folderId"
              },
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "Threads_userViewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "ThreadsPaginateQuery",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v15/*: any*/),
          (v16/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "role",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v17/*: any*/),
                "concreteType": "ThreadConnection",
                "kind": "LinkedField",
                "name": "threads",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ThreadEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Thread",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "threadId",
                            "storageKey": null
                          },
                          (v18/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Folder",
                            "kind": "LinkedField",
                            "name": "folder",
                            "plural": false,
                            "selections": [
                              (v18/*: any*/),
                              (v19/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "color",
                                "storageKey": null
                              },
                              (v16/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "wMemberId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "wChannelId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Channel",
                            "kind": "LinkedField",
                            "name": "wChannel",
                            "plural": false,
                            "selections": [
                              (v19/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "integrationIcon",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "integrationName",
                                "storageKey": null
                              },
                              (v16/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "etag",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "updatedAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastChatAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastMessage",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastReadAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "unreadCount",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Member",
                            "kind": "LinkedField",
                            "name": "member",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "groupChat",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "meta",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "externalId",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "externalProfile",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "MemberProfile",
                                "kind": "LinkedField",
                                "name": "inboxProfile",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "firstName",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "lastName",
                                    "storageKey": null
                                  },
                                  (v19/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "profilePicUrl",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v16/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v16/*: any*/),
                          {
                            "kind": "ClientExtension",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "newFromInboxAction",
                                "storageKey": null
                              }
                            ]
                          },
                          (v15/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v17/*: any*/),
                "filters": [
                  "search",
                  "folderIds",
                  "wChannelIds",
                  "assigned",
                  "onlyUnread"
                ],
                "handle": "connection",
                "key": "Threads_threads",
                "kind": "LinkedHandle",
                "name": "threads"
              }
            ],
            "type": "UserScope",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "431c299e57456c345a34b67ee24a14fa",
    "id": null,
    "metadata": {},
    "name": "ThreadsPaginateQuery",
    "operationKind": "query",
    "text": "query ThreadsPaginateQuery(\n  $after: String\n  $assigned: Boolean = false\n  $first: Int = 30\n  $folderId: ID = \"main\"\n  $onlyUnread: Boolean = false\n  $search: String = \"\"\n  $wChannelIds: [ID]\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...Threads_userViewer_1ov4VB\n    id\n  }\n}\n\nfragment Threads_userViewer_1ov4VB on UserScope {\n  role\n  threads(search: $search, folderIds: [$folderId], wChannelIds: $wChannelIds, first: $first, after: $after, assigned: $assigned, onlyUnread: $onlyUnread) {\n    edges {\n      node {\n        threadId\n        folderId\n        folder {\n          folderId\n          name\n          color\n          id\n        }\n        wMemberId\n        wChannelId\n        wChannel {\n          name\n          integrationIcon\n          integrationName\n          id\n        }\n        etag\n        title\n        updatedAt\n        lastChatAt\n        lastMessage\n        lastReadAt\n        unreadCount\n        member {\n          groupChat\n          meta\n          externalId\n          externalProfile\n          inboxProfile {\n            firstName\n            lastName\n            name\n            profilePicUrl\n          }\n          id\n        }\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      totalCount\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n"
  }
};
})();

node.hash = "d5b27cc3192c5242416efede61e385e2";

module.exports = node;
