/**
 * @generated SignedSource<<1c19f6eff6978c55a487cca463d1ab35>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Error",
  "kind": "LinkedField",
  "name": "error",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "RoleBasedAppConfig",
  "kind": "LinkedField",
  "name": "roleBasedAppConfig",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "MASK_EXTERNALID",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "ChannelConfig",
  "kind": "LinkedField",
  "name": "defaultChannelConfig",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RoleBasedConfig",
      "kind": "LinkedField",
      "name": "roleBasedConfig",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "SEND_MESSAGE_ALL",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "SEND_ATTACHMENT",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "SEND_AUDIO",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "TRANSFER_TO_UNASSOCIATED_FOLDERS",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ChannelAutomationConfig",
      "kind": "LinkedField",
      "name": "automation",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "INBOX_AGENT_INTERNAL_COMMENT",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "INBOX_AGENT_MESSAGE",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "INBOX_ASSIGN_AGENT",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "INBOX_LIVECHAT_UPDATE",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "MOVE_THREAD_FOLDER",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SettingsModalQuery_UpdateAppMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAppPayload",
        "kind": "LinkedField",
        "name": "updateApp",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "App",
            "kind": "LinkedField",
            "name": "app",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SettingsModalQuery_UpdateAppMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAppPayload",
        "kind": "LinkedField",
        "name": "updateApp",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "App",
            "kind": "LinkedField",
            "name": "app",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0760a82ef37acf722bffc0068d650d07",
    "id": null,
    "metadata": {},
    "name": "SettingsModalQuery_UpdateAppMutation",
    "operationKind": "mutation",
    "text": "mutation SettingsModalQuery_UpdateAppMutation(\n  $input: UpdateAppInput!\n) {\n  updateApp(input: $input) {\n    clientMutationId\n    error {\n      code\n      message\n    }\n    app {\n      roleBasedAppConfig {\n        MASK_EXTERNALID\n      }\n      defaultChannelConfig {\n        roleBasedConfig {\n          SEND_MESSAGE_ALL\n          SEND_ATTACHMENT\n          SEND_AUDIO\n          TRANSFER_TO_UNASSOCIATED_FOLDERS\n        }\n        automation {\n          INBOX_AGENT_INTERNAL_COMMENT\n          INBOX_AGENT_MESSAGE\n          INBOX_ASSIGN_AGENT\n          INBOX_LIVECHAT_UPDATE\n          MOVE_THREAD_FOLDER\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

node.hash = "5d456f2dec485e1db38162a0f8f357ec";

module.exports = node;
