/**
 * @generated SignedSource<<a20fcd41c62c79cd0906ec8c13258505>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingsOrganizationConfig_userViewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Integration",
      "kind": "LinkedField",
      "name": "folderActions",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "integrationIcon",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "integrationName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "integrationId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "App",
      "kind": "LinkedField",
      "name": "app",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RoleBasedAppConfig",
          "kind": "LinkedField",
          "name": "roleBasedAppConfig",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "MASK_EXTERNALID",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ChannelConfig",
          "kind": "LinkedField",
          "name": "defaultChannelConfig",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ChannelAutomationConfig",
              "kind": "LinkedField",
              "name": "automation",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "INBOX_AGENT_INTERNAL_COMMENT",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "INBOX_AGENT_MESSAGE",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "INBOX_ASSIGN_AGENT",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "INBOX_LIVECHAT_UPDATE",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "MOVE_THREAD_FOLDER",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "RoleBasedConfig",
              "kind": "LinkedField",
              "name": "roleBasedConfig",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "SEND_MESSAGE_ALL",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "SEND_ATTACHMENT",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "SEND_AUDIO",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "TRANSFER_TO_UNASSOCIATED_FOLDERS",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserScope",
  "abstractKey": null
};

node.hash = "5d2c31008eb774ab028339f4bf867525";

module.exports = node;
